@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

*{
    margin: 0;
    padding: 0;
}

body{
    background-color: #e2e2e2;
    color: rgb(34, 34, 34);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.wrapper{
    width: 100%;
}

header{
    background-color: #0d2973;
    color: #fafafa;
    font-weight: 300;
    width: 100%;
    height: 100px;
    text-align: center;

}
header .logo{
    height: 10%;
    
    font-weight: 600;
    
    word-spacing: 5px;
    font-size:15px;
    z-index: 0;
    
}

header .nav{
    
    list-style: none;
    position: relative;
}

header ul.nav li{
    display: inline;
    margin: 5px;

    cursor: pointer;
    transition: opacity 500ms;
}

header ul.nav li:hover{
    opacity: 50%;
}

header .shop-cart-button{
    float: right;
    cursor: pointer;
    transition: color 500ms ease;
    margin-right: 10px;
    margin-top: 2px;
}

header .shop-cart-button:hover,
header .shop-cart-button.active{
    color:#db2c2c;
}

header .shop-cart{
    position: absolute;
    top: 30px;
    right:0;
    width: 450px;
    background: #fafafa;
    -webkit-box-shadow: -21px 13px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -21px 13px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: -21px 13px 8px 0px rgba(34, 60, 80, 0.2);
    z-index: 1000;
    padding: 20px;
    padding-bottom: 0;
}

header.shop-cart.empty h2{
    font-size: 20px;
    margin-bottom: 20px;
    color:#0d2973;
}

header .shop-cart .item{
    width: 100%;
    float: left;
    margin-bottom: 20px;
    color:#0d2973;
}

header .shop-cart .item img{
    width: 70px;
    float: left;
    margin-right: 20px;
}

header .shop-cart .item h2{
    font-size: 20px;
    margin-bottom: 10px;
    color:#0d2973;
}

header .shop-cart .item p{
    font-weight: 600;
    color:#0d2973;
}

header .presentation{
    background: #e2e2e2;
    color: #0d2973;
    margin: 0 0;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    position: relative;
    
}

footer  {
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
}

main{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

main .item{
    border-radius: 20px 20px 20px 20px;
    width: 40%;
    margin-bottom: 10px;
    margin: 10px;
    background: #6fb8e3;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
}



main .item img:hover{
    transform: scale(1.05);

}

main {
    
    color: #fafafa;
    align-items: end;
    
}

main .ruble, header .ruble{
    color: black
}

main .item h2{
    font-size: 20px;
    white-space: normal;
}

main .item h2, main .item p, main .item b{
    padding: 0 10px;
}

main .item img{
    width: 100%;
    object-fit: cover;
    border-radius: 0 0 20px 20px;
    transition: transform 500ms ease;
    
}
    
main b{
    font-weight: 600;
    color:#db2c2c
}

main .add-to-cart{
    position: absolute;
    right: 5%;
    bottom: 5%;
    background: #0d2973;
    width: 20px;
    height: 20px;
    text-align: center;
    color:white;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: transform 600ms;
}

main .add-to-cart:hover{
    transform: scale(1.5) translateY(-2px);
}

